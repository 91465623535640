@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");

@font-face {
  font-family: "uknumberplate";
  src: url("../public/fonts/uknumberplate.eot");
  src: url("../public/fonts/uknumberplate.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/uknumberplate.woff2") format("woff2"),
    url("../public/fonts/uknumberplate.woff") format("woff"),
    url("../public/fonts/uknumberplate.ttf") format("truetype"),
    url("../public/fonts/uknumberplate.svg#uknumberplate") format("svg");
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #c0c0c0 !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #c0c0c0 !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #c0c0c0 !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #c0c0c0 !important;
}

body {
  box-sizing: border-box;
}

.login-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  border-radius: 20px;
  background-color: #ffffff;
  padding: 65px 50px;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.12);
  max-width: 700px;
  margin: 0 auto;
}

.login-form .form-group label {
  color: #000;
  font-size: 15px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  width: 100%;
}

.login-form .form-group {
  margin-bottom: 15px;
  position: relative;
}
.login-form .form-group input {
  padding: 12px 24px;
  border-radius: 8px;
  border: 1px solid #d6d6d6;
  color: #a6a6a6;
  font-size: 16px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}
button.eyes-icon {
  position: absolute;
  right: 15px;
  background-color: transparent;
  border: 0;
  bottom: 11px;
  font-size: 18px;
}
.login-logo {
  text-align: center;
  margin-bottom: 30px;
}
.login-button button {
  border-radius: 5px;
  background: #fee100;
  padding: 15px 0px;
  width: 100%;
  border-color: #fee100;
  color: #000;
  text-align: center;
  font-size: 20px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-transform: capitalize;
}

.login-button {
  margin-top: 30px;
}

.login-button button:hover {
  background-color: #fee100;
  border-color: #fee100;
  color: #000;
}

/***************/
.page-wraper {
  display: flex;
  width: 100%;
  align-items: stretch;
  background: #f8f8f8;
}
.sidebar.open {
  margin-left: -200px;
}
.page-wraper-content {
  width: 100%;
  padding: 50px 20px;
  min-height: 100vh;
  transition: all 0.3s;
}
.sidebar {
  width: 232px;
  color: #fff;
  transition: all 0.3s;
  background: #000;
  padding-top: 10px;
}

.sidebar.open {
  transform: translateX(0);
}
.sidebar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 25px;
}

.sidebar-nav li a {
  color: #fff;
  font-size: 16px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  text-decoration: none;
  padding: 20px 16px;
  display: block;
}

.sidebar-nav li a.active {
  background: #fee100;
  color: #000;
}
.sidebar-logo {
  text-align: center;
}

.sidebar-nav li a.active svg path {
  fill: #000;
}

.sidebar-nav li a span {
  margin-right: 10px;
}
.menu-toggle {
  cursor: pointer;
  display: none;
}

.menu-toggle span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #000;
  margin-bottom: 5px;
}

.menu {
  list-style-type: none;
  padding: 0;
  margin-top: 50px;
}

.menu li {
  padding: 10px;
  cursor: pointer;
}

.menu li:hover {
  background-color: #555;
}

/***************/
.datatable0 .kdYplh {
  background: #000;
  color: #fff;
  font-size: 15px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.datatable0 .eBJjZC {
  color: #454545;
  font-size: 15px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  justify-content: center;
  min-width: auto;
}

.table-view {
  display: flex;
  align-items: center;
}

.datatable0 .table-view p {
  margin: 0;
}

.datatable0 .kSXXZB p {
  margin: 0;
}

.dUdLgu {
  padding: 18px 0;
  border-bottom: 1px solid #dedede;
}

.approved-link {
  color: #34a853;
  text-decoration: none;
}

.datatable0 .table-view img {
  margin-right: 10px;
}

.yello-number {
  padding: 4px 16px;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-family: uknumberplate;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-transform: capitalize;
  border-radius: 4px;
  background: #fee100;
  border-color: #fee100;
}
.update-form .form-group label sup {
  color: #ea4335;
}

.edit-delet a {
  display: flex;
  width: 27px;
  height: 27px;
  align-items: center;
  border-radius: 4.5px;
  border: 0.75px solid #ddd;
  justify-content: center;
  margin: 0 3px;
}

.edit-delet {
  display: flex;
  align-items: center;
}
.datatable0 .fstYAH {
  justify-content: center;
}
.table-title h2 {
  color: #000;
  font-size: 24px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}

.table-title {
  margin-bottom: 27px;
}

/***************/
.update-form {
  border-radius: 8px;
  border: 1px solid #dfdfdf;
  background: #fff;
  padding: 40px;
}

.update-form .form-group label {
  color: #000;
  text-align: center;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-transform: capitalize;
}

.form-group select {
  border-radius: 8px;
  border: 1px solid #d6d6d6;
  height: 54px;
  color: #272727;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.update-form .form-control {
  border-radius: 8px;
  border: 1px solid #d6d6d6;
  height: 54px;
  color: #272727;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.plate-form .form-group {
  margin-bottom: 20px;
}

.listing-name {
  background: #f8f8f8;
  padding: 48px 56px;
}

.listing-name ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.listing-name ul li h3 {
  color: var(--body, #333);
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 193%;
  text-transform: capitalize;
}

.listing-name ul li h3 span {
  font-weight: 700;
  padding-right: 8px;
}

.listing-name h2 {
  color: var(--body, #333);
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 200%;
  text-transform: capitalize;
  margin: 0;
  margin-top: 20px;
}

.listing-name h2 span {
  border-radius: 4px;
  background: #fee100;
  padding: 11px 24px;
  margin-left: 8px;
  font-size: 25px;
  font-family: uknumberplate;
}

.plate-form button.btn.btn-primary {
  border-radius: 5px;
  background: #fee100;
  color: #000;
  text-align: center;
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-transform: capitalize;
  border-color: #fee100;
  padding: 22px 24px;
  width: 100%;
}

.plate-form .form-group .input-group-text {
  padding: 0.375rem 1.75rem;
  color: #a6a6a6;
  text-align: center;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.dashboard-card {
  background: #fff;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
}

.dashboard-card h3 {
  color: #000;
  text-align: center;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  text-transform: capitalize;
  margin-bottom: 24px;
}

.dashboard-card h4 {
  color: #000;
  font-family: Rubik;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}

.dashboard-card-img span {
  border-radius: 100px;
  background: #000;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-card-row {
  margin-bottom: 20px;
}
.dashboard-tabel h2 {
  color: #000;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  text-transform: capitalize;
}

.dashboard-tabel {
  margin-bottom: 15px;
}
.update-form.view-adon {
  margin-top: 50px;
}
.table-title.coupons-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
button.btn.coupon-btn {
  border-radius: 4px;
  background: #fee100;
  border-color: #fee100;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  padding: 4px 15px;
}


.form-group label {
    width: 100%;
}

.cron-form .form-group {
    margin-bottom: 15px;
}

.cron-form {
    margin-bottom: 50px;
}

.plate-form .form-group label {
  width: auto;
}
.plate-form.edit-form .react-datepicker-wrapper {
  width: 100%;
}
.update-button {
  max-width: 200px;
  margin-top: 20px;
}
/*******responsive start***********/
@media screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .menu-toggle {
    display: block;
    margin-bottom: 20px;
  }
  .sidebar {
    margin-left: -233px;
  }
  .sidebar.open {
    margin-left: 0;
  }
  .page-wraper-content.open {
    width: 38%;
  }

  .datatable0 .kSXXZB {
    min-width: auto;
  }
  .page-wraper-content {
    padding: 25px 15px;
  }
  .update-form {
    padding: 15px;
  }

  .listing-name {
    padding: 20px 15px;
    margin-top: 25px;
  }

  .listing-name h2 {
    font-size: 14px;
  }

  .listing-name h2 span {
    padding: 11px 6px;
    font-size: 14px;
  }

  .listing-name ul li h3 {
    font-size: 13px;
  }

  .plate-form button.btn.btn-primary {
    padding: 17px 24px;
    font-size: 16px;
  }

  .form-group select {
    height: 50px;
  }

  .update-form .form-control {
    height: 50px;
  }

  .plate-form .form-group {
    margin-bottom: 15px;
  }
  .sidebar-nav li a {
    padding: 15px 16px;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .page-wraper-content {
    width: 74%;
  }

  .sidebar {
    width: 30%;
  }
  .listing-name {
    padding: 40px 10px;
  }

  .listing-name ul li h3 {
    font-size: 13px;
    word-wrap: break-word;
    line-height: 156%;
  }

  .listing-name h2 {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .listing-name h2 span {
    margin-left: 0;
    padding: 8px 24px;
    font-size: 16px;
  }

  .update-form {
    padding: 15px;
  }

  .plate-form button.btn.btn-primary {
    padding: 15px 24px;
    font-size: 16px;
  }

  .plate-form .col-md-3 {
    width: 70%;
  }

  .form-group select {
    height: 45px;
  }

  .update-form .form-control {
    height: 45px;
  }
}

@media screen and (min-device-width: 1025px) and (max-device-width: 1200px) {
  .sidebar {
    width: 25%;
  }

  .page-wraper-content {
    width: 75%;
  }
  .update-form {
    padding: 30px 15px;
  }

  .listing-name {
    padding: 25px 20px;
  }

  .listing-name ul li h3 {
    font-size: 15px;
  }

  .listing-name h2 {
    font-size: 15px;
  }

  .listing-name h2 span {
    padding: 11px 29px;
    font-size: 16px;
  }

  .form-group select {
    height: 45px;
  }

  .update-form .form-control {
    height: 45px;
  }

  .plate-form .col-md-3 {
    width: 50%;
  }

  .plate-form button.btn.btn-primary {
    font-size: 16px;
    padding: 18px 24px;
  }
}

@media screen and (min-device-width: 1201px) and (max-device-width: 1365px) {
  .sidebar {
    width: 20%;
  }

  .page-wraper-content {
    width: 80%;
  }
  .update-form {
    padding: 30px;
  }

  .listing-name {
    padding: 40px 30px;
  }

  .update-form .col-md-3 {
    width: 40%;
  }

  .form-group select {
    height: 50px;
  }

  .update-form .form-control {
    height: 50px;
  }

  .plate-form button.btn.btn-primary {
    padding: 16px 24px;
    font-size: 18px;
  }
  .sidebar-nav li a {
    padding: 15px 16px;
  }
}

@media screen and (min-device-width: 1366px) and (max-device-width: 1900px) {
  .sidebar {
    width: 20%;
  }

  .page-wraper-content {
    width: 80%;
  }
  .update-form {
    padding: 30px;
  }

  .listing-name {
    padding: 40px 30px;
  }

  .update-form .col-md-3 {
    width: 40%;
  }

  .form-group select {
    height: 50px;
  }

  .update-form .form-control {
    height: 50px;
  }

  .plate-form button.btn.btn-primary {
    padding: 16px 24px;
    font-size: 18px;
  }
  .sidebar-nav li a {
    padding: 15px 16px;
  }
}

.number-table thead tr th {
  background-color: #000;
  color: #fff;
  font-size: 15px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 15px 15px;
}

.number-table tbody tr td {
  color: #454545;
  font-size: 15px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding: 15px 15px;
}
.table-title.bottom {
  margin-top: 30px;
}

.date-input .react-datepicker-wrapper {
  width: 100%;
}

.table-title.selet {
  display: flex;
  justify-content: space-between;
}

.search-list {
  display: flex;
  align-items: center;
}



.search-list select {
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  font-size: 16px;
  height: 45px;
  color: #000;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  margin-right: 13px;
}



.search-list button.btn.btn-primary {
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  margin: 0 5px;
  padding: 14px 25px;
  text-align: center;
  text-transform: capitalize;
  background-color: #000;
  border-color: #000;
}

